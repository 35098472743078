<template>
  <DashboardContentLayout>
    <template
      v-if="!isLoading"
      #title>
      <span class="text-32">
        {{ fullName || $t('pages.onboarding.admin_page.not_specified') }}
      </span>
    </template>
    <template
      v-if="!isLoading"
      #title-actions>
      <RiskLevelDropdown
        :risk-level="riskLevel"
        :application-status="applicationStatus"
        @change="changeRiskLevel"/>
    </template>
    <template
      v-if="!isLoading"
      #actions>
      <StatusDropdown
        v-if="isBlocked"
        application-status="BLOCKED"
        :sections="[]"
        :verification-in-progress="false"/>
      <StatusDropdown
        :application-status="applicationStatus"
        :sections="uncompletedSections"
        @on-status-change="onApplicationStatusChange"
        @on-reject="declineApplication"/>
    </template>
    <template v-if="!isLoading">
      <TabLinks
        :tabs="tabs"
        secondary/>
    </template>
    <router-view v-slot="{ Component }">
      <Transition name="page">
        <template v-if="!isLoading">
          <component :is="Component"/>
        </template>
        <template v-else>
          <LoaderStub class="items-center"/>
        </template>
      </Transition>
    </router-view>
  </DashboardContentLayout>
</template>

<script setup lang="ts">
import {
  computed, onBeforeMount, provide, ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import LoaderStub from '@/components/loader-stub.vue';
import TabLinks from '@/components/ui-kit/app-tab-links.vue';
import { translateKey } from '@/i18n/translate';
import DashboardContentLayout from '@/layouts/dashboard/dashboard-content-layout.vue';
import { ONBOARDING_PERSONAL_PROFILE_CHILDREN as tabs } from '@/modules/onboarding-personal-profile/routes';
import RiskLevelDropdown from '@/modules/onboarding-user-profile/components/RiskLevelDropdown.vue';
import StatusDropdown from '@/modules/onboarding-user-profile/components/StatusDropdown.vue';
import { OnboardingAdminRequests } from '@/services/requests';
import { Onboarding } from '@/services/requests/onboarding/Onboarding.types';
import { usePersonalOnboardingStore } from '@/stores';
import { fetchApplication, updateRiskLevel } from '@/stores/OnboardingPersonal';
import { errorNotification, pollerFunction, successNotification } from '@/utils';

const {
  applicationStatus,
  personalDetails,
  completedStages,
  isBlocked,
  riskLevel,
} = usePersonalOnboardingStore();

const { t } = useI18n();

const isLoading = ref(true);
const isVerificationInProgress = ref(false);
const { params: { id } } = useRoute();

const fullName = computed(() => {
  if (!personalDetails.value?.firstName && !personalDetails.value?.lastName) {
    return '';
  }

  return [
    personalDetails.value?.firstName,
    personalDetails.value?.lastName,
  ].join(' ');
});

const isApplicationEditable = computed(() => {
  const validStatuses: Onboarding.ApplicationStatus[] = ['NON_VERIFIED', 'VERIFIED', 'IN_PROGRESS', 'MANUAL'];
  if (!applicationStatus?.value) return false;
  return validStatuses.includes(applicationStatus.value);
});

provide('application', { isApplicationEditable });

const uncompletedSections = computed(() => {
  // DEBT: add check for other sections
  const sections: string[] = [];

  const stages = [
    { key: 'PERSONAL_DETAILS', label: 'pages.onboarding.admin_page.personal_information' },
    { key: 'PROOF_OF_IDENTITY', label: 'pages.onboarding.admin_page.proof_of_identity' },
    { key: 'PROOF_OF_ADDRESS', label: 'pages.onboarding.admin_page.proof_of_address' },
    { key: 'RISK_LEVEL', label: 'pages.onboarding.admin_page.risk_level' },
    { key: 'CONTRACT', label: 'pages.onboarding.admin_page.plan' },
  ] as const;

  stages.forEach((stage) => {
    if (!completedStages.value.includes(stage.key)) {
      sections.push(t(stage.label));
    }
  });

  return sections;
});

async function readUserApplication() {
  isLoading.value = true;
  await fetchApplication('admin', true, id);
  isLoading.value = false;

  if (applicationStatus?.value !== 'VERIFYING') return;

  isVerificationInProgress.value = true;
  const exitCondition = () => applicationStatus?.value === 'VERIFIED';
  pollerFunction(() => fetchApplication('admin', true, id), exitCondition, 0, 3)
    .then(() => {
      successNotification(
        translateKey(
          'pages.onboarding.business.company_details.verify.tooltip.success',
          { customer: 'customer' },
        ),
      );
    })
    .finally(() => {
      isVerificationInProgress.value = false;
    });
}

async function declineApplication() {
  isLoading.value = true;
  const { error } = await OnboardingAdminRequests.Individual.declineApplication(id);
  isLoading.value = false;

  if (error) {
    errorNotification(error);
    return;
  }

  await readUserApplication();

  successNotification(
    translateKey(
      'pages.onboarding.business.company_details.decline.tooltip.success',
      { customer: 'customer' },
    ),
  );
}

async function changeRiskLevel(newRiskLevel: Onboarding.RiskLevel) {
  isLoading.value = true;
  const { error } = await updateRiskLevel(newRiskLevel);
  isLoading.value = false;

  if (!error) return;

  errorNotification(error);
}

async function onApplicationStatusChange(status: 'verified' | 'in-progress' | 'no-response' | 'non-verified' | 'onboarding') {
  let request = null;

  switch (status) {
    case 'verified':
      request = OnboardingAdminRequests.Individual.verifyApplication;
      break;
    case 'in-progress':
      request = OnboardingAdminRequests.Individual.inProgressApplication;
      break;
    case 'no-response':
      request = OnboardingAdminRequests.Individual.noResponseApplication;
      break;
    case 'non-verified':
      request = OnboardingAdminRequests.Individual.onNonVerifiedApplication;
      break;
    case 'onboarding':
      request = OnboardingAdminRequests.Individual.onOnboardingApplication;
      break;
    default:
      request = null;
  }

  if (!request) return;

  isLoading.value = true;
  const { error } = await request(id);
  isLoading.value = false;

  if (error) {
    errorNotification(error);
    return;
  }

  await readUserApplication();
}

onBeforeMount(readUserApplication);
</script>
