import { computed } from 'vue';
import { RouteRecordRaw } from 'vue-router';

import OnboardingPersonalLayout from '@/modules/onboarding-personal-profile/components/onboarding-personal-layout.vue';
import PersonalProfileKyc from '@/modules/onboarding-personal-profile/pages/personal-profile-kyc/personal-profile-kyc.vue';
import { RoutePermission } from '@/router/route-permission';
import { usePersonalOnboardingStore } from '@/stores';
import { toggleBlockStatus } from '@/stores/OnboardingPersonal';

const PersonalProfileWallets = () => import('@/modules/onboarding-user-profile/pages/user-profile-wallets/UserProfileWallets.vue');

const PersonalProfileTransactions = () => import('@/modules/transactions-old/pages/transactions.vue');
const PersonalProfileLoginAndSecurity = () => import('@/modules/onboarding-user-profile/pages/user-login-and-security/user-login-and-security.vue');
const PersonalAccountStatus = () => import('@/modules/onboarding-user-profile/pages/user-account-status/user-account-status.vue');
const UserPlan = () => import('@/modules/onboarding-user-profile/pages/user-plan/UserPlan.vue');
const PersonalProfileSubscriptions = () => import('@/modules/user-profile/pages/user-subscriptions/index.vue');
const AccountNavigation = () => import('@/shared-components/account-nav.vue');

const ONBOARDING_PERSONAL_PROFILE_META = {
  groupName: 'onboarding-personal-profile',
  layout: {
    props: {
      backName: [
        'onboarding-users',
      ],
    },
  },
};

export const ONBOARDING_PERSONAL_ACCOUNT_TAB_CHIDLREN: RouteRecordRaw[] = [
  {
    path: 'security',
    name: 'onboarding-personal-account-login-and-security',
    component: PersonalProfileLoginAndSecurity,
    props: () => ({
      userId: usePersonalOnboardingStore().userDetails.value!.userId,
      applicationType: 'personal',
    }),
    meta: {
      translation: 'pages.onboarding.admin_page.security',
      ...ONBOARDING_PERSONAL_PROFILE_META,
    },
  },
  {
    path: 'status',
    name: 'onboarding-personal-account-status',
    component: PersonalAccountStatus,
    props: () => ({
      isBlocked: usePersonalOnboardingStore().isBlocked.value,
      toggleBlockStatus,
    }),
    meta: {
      translation: 'pages.onboarding.admin_page.account_blocking',
      ...ONBOARDING_PERSONAL_PROFILE_META,
    },
  },
];

export const ONBOARDING_PERSONAL_PROFILE_CHILDREN: RouteRecordRaw[] = [
  {
    path: 'profile',
    name: 'onboarding-personal-details',
    component: PersonalProfileKyc,
    meta: {
      translation: 'pages.onboarding.admin_page.profile',
      icon: 'icon-user-onboarding',
      ...ONBOARDING_PERSONAL_PROFILE_META,
    },
  },
  {
    path: 'wallets',
    name: 'onboarding-personal-wallets',
    component: PersonalProfileWallets,
    props: () => ({
      organizationId: usePersonalOnboardingStore().userDetails.value!.organizationId,
    }),
    meta: {
      translation: 'pages.user_profile.navigation.wallets',
      icon: 'icon-wallet',
      ...ONBOARDING_PERSONAL_PROFILE_META,
    },
  },
  {
    path: 'transactions',
    name: 'onboarding-personal-transactions',
    component: PersonalProfileTransactions,
    props: () => ({
      defaultFilter: {
        orgIds: [usePersonalOnboardingStore().userDetails.value!.organizationId],
      },
      isTitleVisible: false,
      isFullPage: false,
    }),
    meta: {
      translation: 'pages.user_profile.navigation.transactions',
      icon: 'icon-list',
      ...ONBOARDING_PERSONAL_PROFILE_META,
    },
  },
  {
    path: 'plan',
    name: 'onboarding-personal-plan',
    component: UserPlan,
    props: () => ({
      userId: usePersonalOnboardingStore().userDetails.value!.userId,
      role: usePersonalOnboardingStore().userDetails.value!.role,
      customerType: 'PERSONAL',
    }),
    meta: {
      translation: 'pages.onboarding.admin_page.plan',
      icon: 'icon-coins',
      ...ONBOARDING_PERSONAL_PROFILE_META,
    },
  },
  {
    path: 'subscriptions',
    name: 'onboarding-personal-subscriptions',
    component: PersonalProfileSubscriptions,
    props: () => ({
      organizationId: usePersonalOnboardingStore().userDetails.value!.organizationId,
    }),
    meta: {
      translation: 'pages.contracts.navigation.subscriptions',
      icon: 'icon-card-thin',
      ...ONBOARDING_PERSONAL_PROFILE_META,
    },
  },
  {
    path: 'account',
    name: 'onboarding-personal-account',
    component: AccountNavigation,
    props: () => ({
      userId: usePersonalOnboardingStore().userDetails.value!.userId,
      tabs: computed(() => {
        const { applicationStatus } = usePersonalOnboardingStore();
        const showAccountStatus = applicationStatus?.value === 'VERIFIED';

        if (showAccountStatus) {
          return ONBOARDING_PERSONAL_ACCOUNT_TAB_CHIDLREN;
        }

        return ONBOARDING_PERSONAL_ACCOUNT_TAB_CHIDLREN
          .filter((tab) => tab.name !== 'onboarding-personal-account-status');
      }),
    }),
    children: ONBOARDING_PERSONAL_ACCOUNT_TAB_CHIDLREN,
    redirect: ONBOARDING_PERSONAL_ACCOUNT_TAB_CHIDLREN[0],
    meta: {
      translation: 'pages.onboarding.admin_page.account',
      icon: 'icon-lock',
      ...ONBOARDING_PERSONAL_PROFILE_META,
    },
  },
];

export const ONBOARDING_PERSONAL: RouteRecordRaw = {
  path: '/dashboard/customers/personal/:id',
  name: ONBOARDING_PERSONAL_PROFILE_META.groupName,
  children: ONBOARDING_PERSONAL_PROFILE_CHILDREN,
  component: OnboardingPersonalLayout,
  redirect: (to) => ({
    name: ONBOARDING_PERSONAL_PROFILE_CHILDREN[0].name,
    params: to.params,
  }),
  meta: {
    permission: RoutePermission.ONBOARDING_USERS,
    root: false,
    ...ONBOARDING_PERSONAL_PROFILE_META,
  },
};
